import { Environment } from './env.interface';

export const environment: Environment = {
  production: true,
  // the '<!--#echo....--> stuff is replace BY NGINX during runtime!
  apiUrl: '<!--#echo var="ssiPHP_HOST"-->/api/',
  useProxy: false,
  proxyUrl: '',
  fetchUrlWhitelist: [/^[a-z]+:\/\//, /^\/assets\//, /^\/config\//, /^\/skin\//, /^\/notifications\//, /^\/NEW\/assets\//, /^\/NEW\/config\//, /^\/NEW\/skin\//, /^\/tutorials\//],
  endpointOverrides: {
    // '/v2/config': 'https://45.12.50.123.nip.io/v2/config'
    // '/objects\/gpus(.*)/': 'https://45.144.135.91.nip.io',
    // '/objects\/gpu_flavors(.*)/': 'https://45.144.135.91.nip.io',
    // '/requests\/8c831dae(.*)/': 'https://45.144.135.91.nip.io',
    // '/objects\/locations(.*)/': 'https://45.144.135.91.nip.io',
  },
  sentryLogErrors: true,
  testingKarma: false
};
